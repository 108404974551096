import React, { Component } from "react";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Footer from "../../components/Footer";
import { Styles } from "./styles/supportToGovt.js";
import Header from "../../components/Header";
import ActivityComponent from "../../components/ActivityComponent";

class SupportToGovt extends Component {
  render() {
    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper about-page">
          {/* Header 2 */}
          <Header />

          {/* Breadcroumb */}
          <BreadcrumbBox title="Support To Govt" img="2.jpg" />

          {/* SupportToGovt Area */}
          <ActivityComponent />

          {/* Footer 2 */}
          <Footer />
        </div>
      </Styles>
    );
  }
}

export default SupportToGovt;
